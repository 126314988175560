import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import Header from "../components/header"
import Footer from "../components/footer"

export const query = graphql`
  query Get404De {
    strapiPageNotFound(locale: { eq: "de" }) {
      Heading
      Content {
        data {
          Content
        }
      }
      localizations {
        data {
          attributes {
            locale
          }
        }
      }
    }

    site {
      siteMetadata {
        title
      }
    }

    strapiContact(locale: { eq: "de" }) {
      locale
      localizations {
        data {
          attributes {
            slug
            locale
          }
        }
      }
      slug
    }

    strapiFooter(locale: { eq: "de" }) {
      ContactText
      Heading
      RightsClaim
      SocialHeading
      Text
      Secondary {
        Link
        Text
      }
      Location {
        Heading
        Text {
          data {
            Text
          }
        }
      }
      Footer {
        Link
        Text
      }
    }

    allStrapiHeader(filter: { locale: { eq: "de" } }) {
      nodes {
        locale
        MobileContactButtonText
        MobileContactHeading
        MobileContactText
        Links {
          Link
          Text
        }
        ContactText
      }
    }

    strapiHeader(locale: { eq: "de" }) {
      MobileContactButtonText
      MobileContactHeading
      MobileContactText
      Links {
        Link
        Text
      }
      ContactText
    }

    strapiGeneralTranslation(locale: { eq: "de" }) {
      AllRightsReserved
      BackToBlog
      By
      Client
      CopiedToClipboard
      Keys
      ReadMore
      ScrollDownOrJustUse
      ViewProject
      CookieSettings
    }

    strapiGeneralSetting(locale: { eq: "de" }) {
      SocialLinks {
        Facebook
        LinkedIn
        Twitter
      }
    }
  }
`

const NotFoundPage = props => (
  <>
    <Header
      siteTitle={props.data.site.siteMetadata?.title || `Title`}
      data={props.data.allStrapiHeader.nodes.find(
        header => header.locale === props.pageContext.langKey
      )}
      contactSlug={props.data.strapiContact.slug}
    />
    <Layout>
      <Seo title="404: Not found" />
      <div className={"page-not-found"}>
        <h1>{props.data.strapiPageNotFound.Heading}</h1>
        <ReactMarkdown
          source={props.data.strapiPageNotFound.Content?.data?.Content}
          escapeHtml={false}
        >
          {props.data.strapiPageNotFound.Content?.data?.Content}
        </ReactMarkdown>
      </div>
    </Layout>
    <Footer
      contactSlug={props.data.strapiContact.slug}
      cookieSettingsText={props.data.strapiGeneralTranslation.CookieSettings}
      socialLinks={props.data.strapiGeneralSetting.SocialLinks}
      data={props.data.strapiFooter}
    />
  </>
)

export default NotFoundPage
